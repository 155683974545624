import { tradeStatsList } from '@/api/trade_stat'


export const module_config = {
  TRADE_MONT_STATS: {
    name: '月度汇总',
    api: tradeStatsList,
    chars: [],
    fields: {
      '序号': 'index',
      '红人昵称': 'artist_nickname',
      '合作时长': '_duration',
      '月份': '_month',
      'GMV': 'gmv'
    }
  },
  TRADE_MONT_STATS_DY: {
    name: '月度汇总',
    api: tradeStatsList,
    chars: [],
    fields: {
      '序号': 'index',
      '红人昵称': 'artist_nickname',
      '合作时长': '_duration',
      '月份': '_month',
      '退前总GMW': 'gmv_return_bef',
      '退后总GMW': 'gmv_return_aft',
      '结算总GMV': 'gmv_settle',
      '退前总佣金': 'ci_return_bef',
      '退后总佣金': 'ci_return_aft',
      '结算总佣金': 'ci',
      '订单退货率': '_rate_return',
      '订单佣金率': '_rate_ci',
      '订单结算比': '_rate_done',
    }
  }
}
