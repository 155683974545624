<template>
  <div class="trade_stat_container__tip_validate">
    <!--  场景一：没有数据，引导导入。  -->
    <el-empty v-if="scene==='NO_TRADES'">
      <div slot="description" style="color: #8a8e99;font-size: 16px;margin-top: 20px;">
        {{ date|parseTime('{y}年{m}月') }}{{ platformName }}暂无数据
        <div style="font-size: 14px;margin-top: 16px;">
          可前往
          <span class="span-btn" @click="handleToImport">"平台数据"</span>
          页面导入数据
        </div>
      </div>
    </el-empty>
    <!--  场景二：导入中。  -->
    <el-empty v-else-if="scene==='IMPORT_DOING'">
      <div slot="description" style="color: #8a8e99;font-size: 16px;margin-top: 20px;">
        <i class="el-icon-loading" />
        {{ date|parseTime('{y}年{m}月') }}{{ platformName }}数据导入中，请耐心等候
        <div style="font-size: 14px;margin-top: 16px;">
          导入中，<span class="span-btn" @click="init">刷新</span>
        </div>
      </div>
    </el-empty>
    <!--  场景三：导入完成，没有配置规则，引导配置。  -->
    <div v-else-if="scene==='NO_CONFIG'" style="text-align: center;margin: 150px auto 0 auto;color: #8a8e99;">
      <i class="el-icon-s-tools" style="font-size: 64px;" />
      <div slot="description" style="font-size: 16px;margin-top: 20px;">
        {{ date|parseTime('{y}年{m}月') }}{{ platformName }}汇总规则未配置
        <div style="font-size: 14px;margin-top: 16px;">
          <trade-stat-config :month="date" text @success="init" />
        </div>
      </div>
    </div>
    <!--  场景四：配置完成，生成数据，生成中。  -->
    <el-empty v-if="scene==='NO_STAT'">
      <div slot="description" style="color: #8a8e99;font-size: 16px;margin-top: 20px;">
        {{ date|parseTime('{y}年{m}月') }} ，汇总数据未初始化。
        <ml-button round text="点击初始化数据" @click.native="handleInitStats" />
      </div>
    </el-empty>
  </div>
</template>
<script>
import { tradeStatsInit, tradeStatsListValidate } from '@/api/trade_stat'
import TradeStatConfig from '@/pages/trade_stats/component/stat-config/index.vue'
import MlButton from '@/components/MlButton/index.vue'

export default {
  name: 'TSTipValidate',
  components: { MlButton, TradeStatConfig },
  props: {
    date: {
      type: String,
      required: true
    },
    platformCode: {
      type: String,
      required: true
    },
    platformName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      scene: 'NO_TRADES'
    }
  },
  created() {
    this.init()
  },
  methods: {
    init: async function() {
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const { scene, can_list } = await tradeStatsListValidate({ month: this.date, platform_code: this.platformCode })
      this.$emit('update:can-list', can_list)
      this.scene = scene || 'ERROR'
      setTimeout(() => {
        loading.close()
      }, 500)
    },
    handleToImport: function() {
      const { href } = this.$router.resolve({ name: 'trade' })
      window.open(href, '_blank')
    },
    handleInitStats: async function() {
      await tradeStatsInit({ month: this.date, platform_code: this.platformCode })
      this.$message.success('初始化任务发布，请稍后查看数据。')
    }
  }
}

</script>
<style lang="scss">
.trade_stat_container__tip_validate {
  .span-btn {
    color: rgb(254, 52, 110);
    cursor: pointer;
  }
}
</style>
