<template>
  <div class="trade_stat_container">
    <el-form inline :model="search" class="trade_stat_container__search">
      <el-form-item>
        <artist-search :artist-id.sync="search.artist_id" @handleSelect='handleSearch' />
      </el-form-item>
      <el-form-item>
        <el-select v-model="search.live_type" placeholder="类型" @change="handleSearch" clearable>
          <el-option label="达播" value="1" />
          <el-option label="日播" value="2" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="handleSearch">查询</el-button>
        <export-by-page v-if="userPermissions.indexOf('bs_trade_stats_export')>-1"
                        :module="search.platform_code==='douyin'?'TRADE_MONT_STATS_DY':'TRADE_MONT_STATS'"
                        :search="search" icon="" :filename="filename" />
      </el-form-item>
      <el-form-item style="float: right;">
        <div style="display: flex;align-items: center;">
          <el-date-picker v-model="search.month" value-format="yyyy-MM-dd" type="month" :clearable="false" />
          {{ search.month|parseTime('{y}年{m}月') }}
        </div>
      </el-form-item>
    </el-form>
    <div class="trade_stat_container__main">
      <div class="trade_stat_container__tool">
        <div class="trade_stat_container__platforms">
          <span v-for="platform in platforms" :key="platform.code"
                :class="{active:search.platform_code===platform.code}"
                @click="search.platform_code=platform.code"
          >
            {{ platform.name }}
          </span>
        </div>
        <div>
          <span v-if="need_init" style="font-size: 12px;margin-right: 8px;color: #999;">建议初始化数据</span>
          <ml-button round text="初始化数据" @click.native="handleInitStats" />
          <trade-stat-config :month="search.month" @success="getListStatus" />
        </div>
      </div>
      <div style="height: calc(100% - 50px)">
        <t-s-tip-validate
          ref="DOM_READY"
          v-show="!can_list"
          :key="search.month+search.platform_code"
          :date="search.month"
          :platform-code="search.platform_code"
          :platform-name="search.platform_name"
          :can-list.sync="can_list"
        />
        <div v-watermark="filename" v-if="can_list"
             style="height: 100%;margin-top: 24px;display: flex;justify-content: space-between;">
          <el-table ref="TABLE" :search="search" :data="table"
                    show-summary :summary-method="getSummaries" height="100%"
                    style="width: calc(100% - 11.5vw);">
            <el-table-column type="index" label="序号" width="55" />
            <el-table-column label="红人昵称" prop="artist_nickname" width="150" show-overflow-tooltip>
              <template v-slot="{row}">
                <artist-nickname :info='{artist_id:row.artist_id,nickname:row.artist_nickname}' />
              </template>
            </el-table-column>
            <template v-if="search.platform_code==='douyin'">
              <el-table-column label="退前总GMW" align="right" prop="gmv_return_bef" sortable>
                <template v-slot="{row:{gmv_return_bef}}">
                  ￥<span class="num-ph">{{ gmv_return_bef|numberFormat(2) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="退后总GMW" align="right" prop="gmv_return_aft" sortable>
                <template v-slot="{row:{gmv_return_aft}}">
                  ￥<span class="num-ph">{{ gmv_return_aft|numberFormat(2) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="结算总GMV" align="right" prop="gmv_settle" sortable>
                <template v-slot="{row:{gmv_settle}}">
                  ￥<span class="num-ph">{{ gmv_settle|numberFormat(2) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="退前总佣金" align="right" prop="ci_return_bef" sortable>
                <template v-slot="{row:{ci_return_bef}}">
                  ￥<span class="num-ph">{{ ci_return_bef|numberFormat(2) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="退后总佣金" align="right" prop="ci_return_aft" sortable>
                <template v-slot="{row:{ci_return_aft}}">
                  ￥<span class="num-ph">{{ ci_return_aft|numberFormat(2) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="结算总佣金" align="right" prop="ci" sortable>
                <template v-slot="{row:{ci}}">
                  ￥<span class="num-ph">{{ ci|numberFormat(2) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="订单退货率" align="right" prop="rate_return" sortable>
                <template v-slot="{row:{_rate_return}}">
                  <span class="num-ph">{{ _rate_return }}</span>
                </template>
              </el-table-column>
              <el-table-column label="订单佣金率" align="right" prop="rate_ci" sortable>
                <template v-slot="{row:{_rate_ci}}">
                  <span class="num-ph">{{ _rate_ci }}</span>
                </template>
              </el-table-column>
              <el-table-column label="订单结算比" align="right" prop="rate_done" sortable>
                <template v-slot="{row:{_rate_done}}">
                  <span class="num-ph">{{ _rate_done }}</span>
                </template>
              </el-table-column>
            </template>
            <template v-else>
              <el-table-column label="合作时长" prop="duration">
                <template v-slot="{row:{duration}}">
                  {{ duration }}个月
                </template>
              </el-table-column>
              <el-table-column label="月份" prop="month">
                <template v-slot="{row:{month}}">
                  {{ month|parseTime('{y}年{m}月') }}
                </template>
              </el-table-column>
              <el-table-column label="总GMV" align="right" prop="gmv" sortable>
                <template v-slot="{row:{gmv}}">
                  ￥<span class="num-ph">{{ gmv|numberFormat(2) }}</span>
                </template>
              </el-table-column>
            </template>
          </el-table>
          <div class="trade_stat_container__list-desc">
            <div v-if="search.platform_code==='douyin'" style="border-bottom: 1px solid #f2f2f2;">
              <h3>订单退货率=(退前总GMV - 退后总GMV)/退前总GMV</h3>
              <h3>订单佣金率=退后总佣金/退后总GMV</h3>
              <h3>订单结算比=订单结算总佣金/退后总佣金</h3>
            </div>
            <h3>订单统计范围：</h3>
            <div v-if="configs&&Array.isArray(configs)">
              <div v-for="config in configs" :key="config.platform_code">
                <h4>{{ config.platform_name }}</h4>
                <ul>
                  <li v-if="config.order_status__not_in&&config.order_status__not_in.length">
                    订单状态：{{ Array.isArray(config.order_status__not_in) ? config.order_status__not_in.join('、') : ''
                    }}，不合计到总GMV
                  </li>
                  <li v-if="config.flow_source__not_in&&config.flow_source__not_in.length">
                    流量来源：{{ Array.isArray(config.flow_source__not_in) ? config.flow_source__not_in.join('、') : ''
                    }}，不合计到总GMV
                  </li>
                  <li v-if="config.shop_names">店铺名称：{{ config.shop_names || '-' }}，不合计到总GMV</li>
                  <li v-if="config.amount__max">用户实际支付金额，小于等于￥{{ config.amount__max }}的，不合计到总GMV</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import TSTipValidate from '@/pages/trade_stats/component/tip-validate.vue'
import MlTable from '@/components/MlTable/index.vue'
import { tradeStatsInit, tradeStatsList, tradeStatsListStatus } from '@/api/trade_stat'
import ArtistSearch from '@/components/artist/ArtistSearch.vue'
import MlButton from '@/components/MlButton/index.vue'
import TradeStatConfig from '@/pages/trade_stats/component/stat-config/index.vue'
import ExportByPage from '@/components/export/ByPage/index.vue'
import { parseTime } from '@/utils'
import { mapGetters } from 'vuex'
import { numberFormat } from '@/utils/utils'

let platforms = localStorage.getItem('platforms')
platforms = platforms ? JSON.parse(platforms) : []
platforms.unshift({ name: '全平台', code: '' })

export default {
  components: { ExportByPage, TradeStatConfig, MlButton, ArtistSearch, MlTable, TSTipValidate },
  data() {
    return {
      platforms,
      can_list: false,
      need_init: false,
      configs: [],
      table: [],
      search: {
        month: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        platform_code: '',
        platform_name: '全平台'
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    filename() {
      const month = parseTime(this.search.month, '{y}年{m}月')
      return `${month}${this.search.platform_name}月度汇总`
    }
  },
  watch: {
    'search.platform_code'(_) {
      this.can_list = false
      if (_ === '') {
        this.search.platform_name = '全平台'
      } else {
        const tmp = platforms.find(platform => {
          return platform.code === _
        })
        this.search.platform_name = tmp.name || '未知'
      }
      this.handleSearch()
    },
    'search.month'() {
      this.can_list = false
      this.handleSearch()
    },
    can_list(_) {
      if (_) {
        this.handleSearch()
      }
    }
  },
  methods: {
    handleConfig: function() {
      this.$refs.DOM_READY && this.$refs.DOM_READY.handleConfig()
    },
    handleInitStats: async function() {
      await tradeStatsInit({ month: this.search.month, platform_code: this.search.platform_code })
      this.$message.success('初始化任务发布，请稍后查看数据。')
    },
    getListStatus: async function() {
      this.need_init = false
      const { need_init, configs } = await tradeStatsListStatus({ month: this.search.month })
      this.need_init = need_init ?? false
      this.configs = configs || []
    },
    handleSearch: function() {
      this.getTable()
      this.getListStatus()
    },
    getTable: async function() {
      if (this.loading) {
        return
      }
      this.loading = true
      const { list } = await tradeStatsList(this.getParam())
      setTimeout(() => {
        this.loading = false
      }, 80)
      this.table = list || []
    },
    getParam: function() {
      const params = { ...{}, ...this.search }
      for (const paramsKey in params) {
        const value = params[paramsKey]
        if (value === '' || value === null) {
          delete params[paramsKey]
        }
      }
      return params
    },
    getSummaries: function(param) {
      const { columns, data } = param
      const sums = []
      const obj = {}
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '汇总'
          return
        }
        const { property } = column
        if (!property || ['artist_nickname', 'duration', 'month'].includes(property)) {
          sums[index] = ''
          return
        } else if (['rate_return', 'rate_ci', 'rate_done'].includes(property)) {
          obj.gmv_return_bef = obj.gmv_return_bef || 0
          obj.gmv_return_aft = obj.gmv_return_aft || 0
          obj.ci_return_aft = obj.ci_return_aft || 0
          obj.ci = obj.ci || 0
          if ('rate_return' === property) {
            // 订单退货率 = (退前总GMV - 退后总GMV) / 退前总GMV
            sums[index] = obj.gmv_return_bef > 0 ? (obj.gmv_return_bef - obj.gmv_return_aft) / obj.gmv_return_bef * 100 : 0
          } else if ('rate_ci' === property) {
            // 订单佣金率 = 退后总佣金/退后总GMV
            sums[index] = obj.gmv_return_aft > 0 ? obj.ci_return_aft / obj.gmv_return_aft * 100 : 0
          } else if ('rate_done' === property) {
            // 订单结算比 = 订单结算总佣金/退后总佣金
            sums[index] = obj.ci_return_aft > 0 ? obj.ci / obj.ci_return_aft * 100 : 0
          }
          sums[index] = numberFormat(sums[index], 2) + '%'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          obj[property] = sums[index]
          sums[index] = '￥' + numberFormat(sums[index], 2)
        } else {
          obj[property] = 0
          sums[index] = ''
        }
      })

      return sums
    }
  }
}
</script>
<style lang="scss">
.trade_stat_main {
  margin: 12px !important;
  //background: #fff !important;
  .page-content {
    min-height: unset !important;
  }

  .main-page-content {
    padding: 0 12px 12px 12px !important;
    min-height: unset !important;
  }

  .el-date-editor {
    width: 29px !important;
    height: 24px !important;

    .el-input__inner {
      display: none;
    }

    .el-icon-date {
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 2px;
      margin-right: 4px;
      cursor: pointer;
      color: #fe346e;
    }
  }

  .trade_stat_container {
    height: calc(100vh - 138px - 24px);
    padding: 12px;

    &__search {
      border-bottom: 1px solid rgb(242, 242, 242);

      .el-form-item--mini.el-form-item {
        margin-bottom: 12px !important;
      }
    }

    &__main {
      padding: 12px 0;
      height: calc(100% - 50px);
    }

    &__tool {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__platforms {
      color: #8a8e99;

      span {
        cursor: pointer;
        padding: 8px 20px;
      }

      span.active {
        color: #fe346e;
        font-weight: 700;
      }

      span:first-child {
        padding-left: 0 !important;
      }
    }

    &__list-desc {
      border-radius: 18px;
      padding: 0.62vw 1.248vw;
      font-size: 12px;
      margin-bottom: 20px;
      color: #606266;
      width: 10vw;
      background: linear-gradient(260deg, #fff3f8, #fffbfd 80%, #fffcfd 179%);

      li {
        list-style: disc !important;
        margin-left: 26px;
      }

      li:first-child {
        margin-top: 12px;
      }
    }
  }

  .el-table, .el-table tr {
    background-color: transparent !important;
  }

  .el-table td.el-table__cell div {
    font-family: "PH Medium";
    font-weight: bold;
  }

}
</style>
