import * as request from '@/utils/request'

/**
 * 自动生成 api代码【模块名：平台订单数据】
 * 生成日期：2024/11/21 15:12:49
 * 生成路径: src/api/trade.js
 * 生成人：超级管理员
 */

/**
 * @description 获取列表
 * @param {*} data
 * @returns
 */
export function getTradeList(data) {
  return request.post('/trade/list', data)
}

/**
 * @description 保存
 * @param {*} data
 * @returns
 */
export function saveTrade(data) {
  return request.post('/trade/store', data)
}

/**
 * @description 获取详情
 * @param id
 * @returns
 */
export function getTradeInfo(id) {
  let data = { id: id }
  return request.post('/trade/get_info', data)
}

/**
 * @description 获取详情
 * @param where
 * @returns
 */
export function queryTradeInfo(where) {
  return request.post('/trade/query', where)
}

/**
 * @description 根据id删除单条信息
 * @param id
 * @returns
 */
export function deleteTrade(id) {
  let data = { id: id }
  return request.post('/trade/delete', data)
}

/**
 * 导入
 * @param data
 * @returns {Promise}
 */
export function importTrades(data) {
  return request.post('/trade/import', data)
}

/**
 * 导出
 * @param data
 * @returns {Promise}
 */
export function exportTrades(data) {
  return request.postBlob('/trade/export', data)
}

/**
 * 可选项
 * @returns {Promise}
 */
export function optionsTrade(data) {
  return request.post('/trade/options', data)
}
